import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Segment,
  Form,
  Breadcrumb,
  Grid,
  Header,
  Image,
  Button,
  Table,
  Icon,
  Dimmer,
  Loader,
  Modal
} from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import {
  getInstructorById,
  getInstructorDocById,
  getAllNotes,
  getAllCompany,
  getAllGroup,
  getInstructorTypes,
  updatePersonnel,
  updateInstructors,
  uploadImageProfile,
  deleteImageProfile,
  getLogbookByID,
  exportLogbook
} from "./service";
import {
  getSpl, getMed
} from "../People/service"
import moment from "moment";

import * as config from "../../config";
import validate from "../../validate";
import HeaderWithMenu from "../../components/headerwithmenu";
import ModalForm from "../../components/modal";
import Wrap from "../../components/wrap";
import FormInstructors from "./instructorForm";
import MainPeople from "../People/main";
import MainButton from "../../components/mainbutton";
import Profile from "../People/profile";
import Notes from "../People/note";
import Documents from "../People/documents";
import Subject from "../People/subject";
import Hours from "../People/Hours";
import Logbook from "../People/Logbook";
import ScheduleById from "../Schedule/scheduleById";
import UploadImage from "../../components/uploadImage";
// import { ReservationTypes } from '../Schedule/data'
import renderDateRangeField from "../../components/renderDateRangeField";
import { DatesRangeInput } from "semantic-ui-calendar-react";

class ProfileInstructors extends Component {
  state = {
    locationState: this.props.location.state,
    bacUserData: JSON.parse(localStorage.getItem("bacUserData")),
    bac_permission: JSON.parse(localStorage.getItem("bac_permission")),
    data: [],
    dataDoc: [],
    dataEvent: [],
    status: 3,
    search: {
      status: 4
    },
    dataEdit: [],
    course: [],
    loading: false,
    options_company: [],
    options_group: [],
    options_instructortype: [],
    options_course: [],
    option_checkcourse: [],
    check_course: [],
    date: '',
    btnLoading: false,
    loadPopup: false,
    errorMessage: null
  };
  handleChangeDate = (event, { name, value }) => {
    console.log(name, value);
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  }
  onClickFilterDate = () => {
    this.setState({ btnLoading: true })
    const { date } = this.state
    const val = date.split(' - ')
    let start = val[0]
    let end = val[1]
    let qString = ''
    if (start && end) {
      qString = '?start=' + start + '&end=' + end
    } else {
      qString = ''
      this.setState({ date: '' })
    }
    getLogbookByID(this.props.location.state.id, qString).then(res => {
      if (res && res.status === 200) {
        this.setState({ logbook: res.data });
      }
      this.setState({ btnLoading: false })
    });

  }
  onClickEdit = () => {
    this.setState({ open: true });
  };
  onClickCancel = () => {
    this.setState({ open: false, openUploadPhoto: false });
  };
  onClickUploadPhoto = () => {
    let { data } = this.state;
    this.setState({
      openUploadPhoto: true,
      imageURL: data.imageURL
    });
  };
  onUploadPhoto = file => {
    if (file) {
      this.setState({ loading: true, uploadError: null });
      let { data } = this.state;
      var formData = new FormData();
      if (typeof file === "object") formData.append("image", file[0]);
      else formData.append("image", file);
      uploadImageProfile(data.id, formData).then(res => {
        if (res && res.status === 200) {
          this.setState({
            imageURL: res.data.imageUrl
          });
          this.getDataInstructors(data.id);
        } else if (res && res.status === 422) {
          this.setState({
            uploadError:
              res.data.errors &&
              res.data.errors.image &&
              res.data.errors.image[0]
          });
        } else {
          this.setState({
            uploadError: "Whoops, looks like something went wrong."
          });
        }
        this.setState({ loading: false });
      });
    }
  };
  onDeletePhoto = () => {
    let { data } = this.state;
    deleteImageProfile(data.id).then(res => {
      if (res && res.status === 200) {
        this.setState({
          imageURL: null
        });
        this.getDataInstructors(data.id);
      }
      this.setState({ loading: false });
    });
  };
  getInstructorType = () => {
    let options = [];
    // checked_instructortype = []
    getInstructorTypes().then(res => {
      if (res && res.status === 200) {
        res.data.map(item => {
          options.push({ key: item.id, text: item.name, value: item.id });
          // checked_instructortype.push(item.id)
        });
        this.setState({
          options_instructortype: options,
          instructortype: res.data
        });
      }
    });
  };
  getSplById = id => {
    getSpl(id).then(res => {
      if (res && res.status === 200) {
        this.setState({ dataSpl: res.data });
      }
    });
  };
  getMedById = id => {
    getMed(id).then(res => {
      if (res && res.status === 200) {
        this.setState({ dataMed: res.data });
      }
    });
  };
  getLogbook = (id) => {
    let qString = ''
    getLogbookByID(id, qString).then(res => {
      if (res && res.status === 200) {
        this.setState({ logbook: res.data });
      }
    });
  };
  getNotes = id => {
    // let qString = "?personnelId=" + id;
    let { bacUserData, bac_permission } = this.state
    getAllNotes(id).then(res => {
      if (res && res.status === 200) {
        let options = []
        // console.log('bacUserData',bacUserData);
        // console.log('bac_permission',bac_permission);
        // console.log('id',id);
        //ต้อง manage ได้ด้วยถึงจะเห็นทั้งหมด
        if (bacUserData.id === id) {
          res.data.map(members => {
            if (members.internal === "false" || members.internal === false) {
              options.push(members)
            }
          })
        } else {
          if (this.checkPermissionMenu("Personnel", ["manage-instructor", "manage-student", "manage-staff", "view-internalnote"])) {
            res.data.map(members => {
              options.push(members)
            })
          } else {
            res.data.map(members => {
              if (members.internal === "false" || members.internal === false) {
                options.push(members)
              }
            })
          }
        }

        this.setState({ note: options, rawNote: res.data });
      }
    });
  };
  getDataInstructors = id => {
    getInstructorById(id).then(res => {
      if (res && res.status === 200) {
        // console.log("getInstructorById", res.data);
        let arrType = [];
        if (res.data.instructorType.length > 0) {
          res.data.instructorType.map(type => arrType.push(type.id));
        }
        let dataSetEdit = {
          isActive: res.data.isActive,
          instructorID: res.data.instructorID,
          position: res.data.position,
          titleEng: res.data.titleEng,
          firstNameEng: res.data.firstNameEng,
          lastNameEng: res.data.lastNameEng,
          titleTh: res.data.titleTh,
          firstNameTh: res.data.firstNameTh,
          lastNameTh: res.data.lastNameTh,
          nickName: res.data.nickName,
          email: res.data.emailAddress,
          personnel_email: res.data.personnel_email,
          phone: res.data.phoneNumber,
          roles: res.data.roles,
          instructorType: arrType, //แก้เป็น [1,2]
          employmenttype: res.data.employmenttype,
          categories: res.data.categories,
          dateOfBirth: res.data.birthDate,
          gender: res.data.gender,
          status: res.data.status,
          checkerType: res.data.checkerType || [],
          engLevel: res.data.engLevel,
          // lastFlightName: res.data.lastFlightName
        };
        this.setState({ data: res.data, dataEdit: dataSetEdit });
      }
    });
  };
  onSubmitEditInstructors = data => {
    // console.log("editxxxxxxxxx", data);
    this.setState({ loading: true });
    if (data.isActive) data.isActive = 'true'
    else data.isActive = 'false'
    let dataInstructors = {
      id: this.props.location.state.id,
      ...data,
      categories: data.instructorType.length > 0 && data.instructorType.some(member => member === 3) ? data.categories : null
    }

    updateInstructors(dataInstructors).then(res => {
      if (res && res.status === 200) {
        this.setState({ open: false, data: [] });
        this.getDataInstructors(this.props.location.state.id);
      }
      this.setState({ loading: false });
    });
  };
  checkPermissionMenu = (group, permission) => {
    let { bac_permission } = this.state;
    let userpermissionGroup = bac_permission.find(item => item.group === group);
    let allowed = permission.find(item => {
      if (userpermissionGroup.permission.indexOf(item) !== -1) return item;
    });
    if (allowed) return true;
    else return false;
  };
  onClickExport = () => {
    this.setState({ loadPopup: true, errorMessage: null })
    const { date } = this.state
    const val = date.split(' - ')
    let start = val[0]
    let end = val[1]
    let qString = ''
    if (start && end) {
      qString = '?start=' + start + '&end=' + end
    } else {
      qString = ''
      this.setState({ date: '' })
    }
    exportLogbook(this.props.location.state.id, qString).then(res => {
      if (res && res.status === 200) {
        this.setState({ loadPopup: false })
        var newBlob = new Blob([res.data]);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var newBlobIE = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          return navigator.msSaveOrOpenBlob(newBlobIE, 'Logbook_' + moment().format('YYYYMMDDHHmmss') + '.xlsx');
        }
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        link.href = data;
        this.setState({ link: link.href })
        link.download = 'Logbook_' + moment().format('YYYYMMDDHHmmss') + '.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL

          window.URL.revokeObjectURL(data);

        }, 100);
      } else {
        this.setState({ errorMessage: 'The request is taking too long. Please try again' })
      }
    })
  }
  componentDidMount() {
    this.getDataInstructors(this.props.location.state.id);
    this.getNotes(this.props.location.state.id);
    this.getInstructorType();
    this.getLogbook(this.props.location.state.id);
    // this.getSplById(this.props.location.state.id);
    // this.getMedById(this.props.location.state.id);

  }

  render() {
    const state = this.state;
    const { handleSubmit, pristine, submitting } = this.props;
    // console.log('state.locationState',state.locationState)
    if (state.data.length === 0)
      return (
        <Segment className="contentSegment" style={{ minHeight: "50vh" }}>
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        </Segment>
      );
    // console.log("ff", state.data);
    return (
      <div>
        <Modal
          size={state.errorMessage ? 'tiny' : 'mini'}
          open={state.loadPopup}
          closeIcon={state.errorMessage ? true : false}
          dimmer="blurring"
          closeOnDimmerClick={false}
          onClose={() => this.setState({ loadPopup: false, errorMessage: null })}>
          <Wrap>
            {state.errorMessage ?
              <Wrap>
                <Modal.Header><Icon name='warning sign' /> Warning</Modal.Header>
                <Modal.Content>
                  {state.errorMessage}
                </Modal.Content>
              </Wrap>
              :
              <Modal.Content>
                <div style={{ textAlign: "center" }}>
                  <Icon loading name="circle notch" />
                  <br />
                    Waiting Download File
                  </div>
              </Modal.Content>
            }
          </Wrap>
        </Modal>
        <ModalForm
          open={state.openUploadPhoto}
          icon="image outline"
          size="small"
          content={`${state.data.firstNameEng} ${state.data.lastNameEng}`}
          close={this.onClickCancel}
          save={this.onUploadPhoto}
        >
          <UploadImage
            uploadError={state.uploadError}
            loading={state.loading}
            imageUrl={state.imageURL}
            onChange={this.onUploadPhoto}
            onClickDelete={this.onDeletePhoto}
          />
        </ModalForm>

        <ModalForm
          icon="edit"
          content="Edit Profile"
          open={state.open}
          close={this.onClickCancel}
        >
          <FormInstructors
            loading={state.loading}
            isAdd={false}
            initialValues={state.dataEdit ? state.dataEdit : null}
            onSubmit={this.onSubmitEditInstructors}
            onCancel={this.onClickCancel}
            options_instructortype={state.options_instructortype}
          />
        </ModalForm>
        <Segment className="contentSegment" textAlign="left">
          <HeaderWithMenu name="Instructors">
            {this.checkPermissionMenu("Personnel", ["manage-instructor"])
              && (
                <Wrap>
                  <Button size="small" content="Edit Profile" onClick={this.onClickEdit} />
                  <Button size="small" content=" Upload Photo" onClick={this.onClickUploadPhoto} />
                </Wrap>
              )
            }
          </HeaderWithMenu>
          <Breadcrumb>
            {!state.locationState.status &&
              <Wrap>
                <Breadcrumb.Section
                  onClick={() => this.props.history.push("/Instructors")}
                  style={{ color: "grey" }}
                >
                  Instructors
                  </Breadcrumb.Section>
                <Breadcrumb.Divider icon="right angle" />
              </Wrap>
            }
            <Breadcrumb.Section active>Instructors Profile</Breadcrumb.Section>
          </Breadcrumb>
          < br />
          <br />
          <Grid>
            <Grid.Row columns={2} divided style={{ paddingBottom: 0 }}>
              <Grid.Column width={4}>
                <Image
                  src={
                    state.data.imageURL ? state.data.imageURL + '?time=' + new Date() :
                      "https://app.flightschedulepro.com/images/instructor-default.gif"
                  }
                  onError={e => {
                    e.target.onerror = null;
                    e.target.src =
                      "https://app.flightschedulepro.com/images/instructor-default.gif";
                  }}
                />
              </Grid.Column>
              <Grid.Column width={12}>
                <Header as="h2" style={{ color: config.colorTheme }}>
                  <Header.Content>
                    {state.data.instructorID && state.data.instructorID}&nbsp;&nbsp;
                     {state.data.firstNameEng && state.data.firstNameEng}&nbsp;&nbsp;
                     {state.data.lastNameEng && state.data.lastNameEng}
                    <Header.Subheader style={{ color: config.colorTheme }}>
                      {state.data.firstNameTh +
                        " " +
                        state.data.lastNameTh +
                        " " +
                        (state.data.nickName
                          ? "( " + state.data.nickName + " )"
                          : "")}
                    </Header.Subheader>
                  </Header.Content>
                </Header>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column width="5">
                            <p className="bold">Position</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.position ? state.data.position : "-"}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            <p className="bold">Phone</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.phoneNumber ? state.data.phoneNumber : "-"}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">BAC Email</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.emailAddress ? state.data.emailAddress : "-"}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">Personnel Email</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.personnel_email ? state.data.personnel_email : "-"}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            <p className="bold">Username</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.username
                              ? state.data.username
                              : "-"}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">Date of Birth</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.birthDate
                              ? moment(state.data.birthDate, 'DD-MM-YYYY').format(
                                "DD MMMM YYYY"
                              )
                              : "-"}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            <p className="bold">Checker Type</p>
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.checkerType && state.data.checkerType.length > 0
                              ? state.data.checkerType.map(item => (
                                <span >
                                  {item} <br />
                                </span>
                              ))
                              : "-"}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            <p className="bold">Instructor Type</p>
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.instructorType.length > 0
                              ? state.data.instructorType.map(item => (
                                <span key={item.name}>
                                  {item.name} <br />
                                </span>
                              ))
                              : "-"}
                          </Grid.Column>
                        </Grid.Row>

                      </Grid>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">Status</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.status ? state.data.status : '-'}
                            {/*state.data.isActive == true || state.data.isActive == "true" ? (
                              <p>Active</p>
                            ) : (
                                <p>Inactive</p> || "-"
                              )*/}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            <p className="bold">Categories</p>
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.categories || "-"}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            <p className="bold">Eng Level</p>
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.engLevel || "-"}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">Work Time</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.employmenttype || "-"}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">Gender</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.gender ? state.data.gender : "-"}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">Last Flight</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.lastFlight
                              ? <span>
                                <span>{state.data.lastFlightName}</span><br />
                                <span>{moment(state.data.lastFlight).format("DD MMMM YYYY HH:mm")}</span>
                              </span>
                              : "-"}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">Last Login</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.lastLogin
                              ? moment(state.data.lastLogin).format(
                                "DD MMMM YYYY HH:mm"
                              )
                              : "-"}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">Created</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.createdAt
                              ? moment(state.data.createdAt).format(
                                "DD MMMM YYYY HH:mm"
                              )
                              : "-"}
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ paddingTop: 0 }}>
              <Grid.Column>
                <MainPeople
                  tabs={[
                    {
                      menuItem: "Notes",
                      render: () => (
                        <Notes
                          page='instructor'
                          personnelId={this.props.location.state.id}
                          getNote={this.getNotes}
                          listNote={state.note}
                        />
                      )
                    },
                    {
                      menuItem: "Documents",
                      render: () => (
                        <Documents
                          page='Instructor'
                          info={state.data}
                          spl={state.dataSpl}
                          med={state.dataMed}
                        />
                      )
                    },
                    {
                      menuItem: "Schedule",
                      render: () => (
                        <div>
                          <ScheduleById id={state.data.id} disableClick />
                        </div>
                      )
                    },
                    {
                      menuItem: "Logbook",
                      render: () => (
                        <div>
                          <Form>
                            <Form.Group>
                              <Form.Field width={5}>
                                {/*<label style={{ textAlign: "left" }}>Filter by Calendar</label>*/}
                                <DatesRangeInput
                                  name="date"
                                  placeholder="From - To"
                                  hideMobileKeyboard
                                  dateFormat="YYYY-MM-DD"
                                  iconPosition="right"
                                  value={this.state.date}
                                  // defaultValue={state.date}
                                  onChange={this.handleChangeDate}
                                  clearable
                                />
                              </Form.Field>
                              <MainButton
                                size='small'
                                text="Filter by Calendar"
                                loading={state.btnLoading}
                                onClick={this.onClickFilterDate} />
                              <Button
                                size='small'
                                onClick={this.onClickExport}
                                color='green'>Export Excel</Button>
                            </Form.Group>
                          </Form>
                          <br />
                          <Logbook
                            data={state.logbook}
                          />
                        </div>
                      )
                    },
                    {
                      menuItem: "Course/Subjects",
                      render: () => (
                        <Subject
                          info={state.data}
                        // course={state.course}
                        // option_course={state.option_course}
                        // option_checkcourse={state.option_checkcourse}
                        />
                      )
                    }
                  ]}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </div>
    );
  }
}

// export default withRouter(ProfileAircraft)

export default ProfileInstructors = reduxForm({
  // a unique name for the form
  form: "instructorsprofile",
  validate,
  enableReinitialize: true
})(ProfileInstructors);
